exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-customer-appreciation-day-boulder-2023-js": () => import("./../../../src/pages/customer-appreciation-day-boulder-2023.js" /* webpackChunkName: "component---src-pages-customer-appreciation-day-boulder-2023-js" */),
  "component---src-pages-deals-js": () => import("./../../../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-get-directions-js": () => import("./../../../src/pages/get-directions.js" /* webpackChunkName: "component---src-pages-get-directions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-boulder-dispensary-js": () => import("./../../../src/pages/locations/boulder-dispensary.js" /* webpackChunkName: "component---src-pages-locations-boulder-dispensary-js" */),
  "component---src-pages-locations-denver-federal-blvd-dispensary-js": () => import("./../../../src/pages/locations/denver-federal-blvd-dispensary.js" /* webpackChunkName: "component---src-pages-locations-denver-federal-blvd-dispensary-js" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-locations-pueblo-dispensary-js": () => import("./../../../src/pages/locations/pueblo-dispensary.js" /* webpackChunkName: "component---src-pages-locations-pueblo-dispensary-js" */),
  "component---src-pages-locations-pueblo-west-dispensary-js": () => import("./../../../src/pages/locations/pueblo-west-dispensary.js" /* webpackChunkName: "component---src-pages-locations-pueblo-west-dispensary-js" */),
  "component---src-pages-menu-boulder-js": () => import("./../../../src/pages/menu/boulder.js" /* webpackChunkName: "component---src-pages-menu-boulder-js" */),
  "component---src-pages-menu-denver-155-federal-js": () => import("./../../../src/pages/menu/denver-155-federal.js" /* webpackChunkName: "component---src-pages-menu-denver-155-federal-js" */),
  "component---src-pages-menu-index-js": () => import("./../../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-pages-menu-pueblo-js": () => import("./../../../src/pages/menu/pueblo.js" /* webpackChunkName: "component---src-pages-menu-pueblo-js" */),
  "component---src-pages-menu-pueblo-west-js": () => import("./../../../src/pages/menu/pueblo-west.js" /* webpackChunkName: "component---src-pages-menu-pueblo-west-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-4-20-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/4-20.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-4-20-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-boulder-weekly-best-new-dispensary-in-boulder-2024-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/boulder-weekly-best-new-dispensary-in-boulder-2024.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-boulder-weekly-best-new-dispensary-in-boulder-2024-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-depot-a-beacon-of-excellence-in-boulder-weeklys-2023-winter-weed-guide-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/cannabis-depot-a-beacon-of-excellence-in-boulder-weeklys-2023-winter-weed-guide.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-depot-a-beacon-of-excellence-in-boulder-weeklys-2023-winter-weed-guide-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-depot-boulder-construction-update-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/cannabis-depot-boulder-construction-update.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-depot-boulder-construction-update-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-for-beginners-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/cannabis-for-beginners.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-for-beginners-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-strains-explained-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/cannabis-strains-explained.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cannabis-strains-explained-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-common-questions-about-cannabis-edibles-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/common-questions-about-cannabis-edibles.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-common-questions-about-cannabis-edibles-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cooking-with-cannabis-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/cooking-with-cannabis.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-cooking-with-cannabis-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-great-products-great-prices-great-staff-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/great-products-great-prices-great-staff.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-great-products-great-prices-great-staff-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-post-game-puff-down-customer-appreciation-day-2023-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/post-game-puff-down-customer-appreciation-day-2023.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-post-game-puff-down-customer-appreciation-day-2023-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-sal-pace-on-the-federal-legalization-of-cannabis-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/sal-pace-on-the-federal-legalization-of-cannabis.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-sal-pace-on-the-federal-legalization-of-cannabis-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-thc-and-cbd-key-differences-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/thc-and-cbd-key-differences.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-thc-and-cbd-key-differences-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-the-basics-cannabis-concentrates-explained-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/the-basics-cannabis-concentrates-explained.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-the-basics-cannabis-concentrates-explained-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-the-entourage-effect-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/the-entourage-effect.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-the-entourage-effect-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-willie-nelsons-luck-summit-benefitting-the-cannabis-voter-project-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/src/markdown-pages/blog-pages/willie-nelsons-luck-summit-benefitting-the-cannabis-voter-project.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-src-markdown-pages-blog-pages-willie-nelsons-luck-summit-benefitting-the-cannabis-voter-project-mdx" */),
  "component---src-templates-landing-page-js-content-file-path-src-markdown-pages-landing-pages-leave-a-review-offer-mdx": () => import("./../../../src/templates/landing-page.js?__contentFilePath=/vercel/path0/src/markdown-pages/landing-pages/leave-a-review-offer.mdx" /* webpackChunkName: "component---src-templates-landing-page-js-content-file-path-src-markdown-pages-landing-pages-leave-a-review-offer-mdx" */),
  "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-cannabis-flower-mdx": () => import("./../../../src/templates/product-page.js?__contentFilePath=/vercel/path0/src/markdown-pages/product-pages/cannabis-flower.mdx" /* webpackChunkName: "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-cannabis-flower-mdx" */),
  "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-edibles-mdx": () => import("./../../../src/templates/product-page.js?__contentFilePath=/vercel/path0/src/markdown-pages/product-pages/edibles.mdx" /* webpackChunkName: "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-edibles-mdx" */),
  "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-pre-rolls-mdx": () => import("./../../../src/templates/product-page.js?__contentFilePath=/vercel/path0/src/markdown-pages/product-pages/pre-rolls.mdx" /* webpackChunkName: "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-pre-rolls-mdx" */),
  "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-vape-cartridges-mdx": () => import("./../../../src/templates/product-page.js?__contentFilePath=/vercel/path0/src/markdown-pages/product-pages/vape-cartridges.mdx" /* webpackChunkName: "component---src-templates-product-page-js-content-file-path-src-markdown-pages-product-pages-vape-cartridges-mdx" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

